
//@ts-nocheck
import Vue from 'vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import SearchTimeIcon from 'icons/search/search-icon-time.svg?inline'
import SearchIcon from 'icons/search/search-icon.svg?inline'
import SearchCategoryIcon from 'icons/search/search-category.svg?inline'
import SchevronIcon from '~/assets/svg/arrow_back.svg?inline'
import RPHProductCard from 'rph/molecules/RPHProductCard/index.vue'
import RPHInputSearch from 'rph/atoms/RPHInputSearch/index.vue'
import CloseIcon from '~/assets/icons/close-common.svg?inline'
import breakpoints from '@/mixins/breakpoints'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'RPHSearchResult',
  components: {
    RPHInputSearch,
    RPHProductCard,
    RPHButton,
    SearchTimeIcon,
    SearchIcon,
    SchevronIcon,
    SearchCategoryIcon,
    CloseIcon,
  },
  mixins: [breakpoints],
  props: {
    type: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    productsArr: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
    },
    showResults: {
      type: Boolean,
      default: false,
    },

    searchFunction: {
      type: Object,
    },
  },
  data() {
    return {
      isHidden: true,
      searchString: '',
      searchHistory: [] as any[],
      popularReqMock: [
        {
          title: 'Жаропонижающие',
        },
        {
          title: 'Обезболивающие',
        },
        {
          title: 'Для печени',
        },
        {
          title: 'Кардио',
        },
        {
          title: 'Капли для носа',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      searchPopularRequests: 'rph-products/searchPopularRequests',
    }),
    isSearchError() {
      return this.$store.getters['rph-products/searchError']
    },

    isSearchEmpty() {
      return this.$store.getters['rph-products/searchEmpty']
    },

    isSearchBased() {
      return this.value === 'Популярные товары'
    },

    searchBlockObject() {
      return this.$store.getters['rph-products/searchBlockObject']
    },

    cardQuantity() {
      let totalQuantity = 4
      if (this.screenWidth < 1390) {
        totalQuantity = 3
      }

      if (this.screenWidth <= 1025) {
        totalQuantity = 5
      }
      return totalQuantity
    },

    isWideTabled() {
      return this.screenWidth <= 1025
    },

    isHistoryVisible() {
      return this.searchHistory.length && (this.isWideTabled ? this.isSearchBased : true)
    },

    isTapsVisible() {
      return (
        !this.isSearchBased &&
        this.searchBlockObject?.taps.length &&
        !this.isSearchEmpty &&
        !this.loading
      )
    },

    isPopularReqVisible() {
      return this.searchPopularRequests.length && (this.isWideTabled ? this.isSearchBased : true)
    },
  },

  watch: {
    searchString(val, newVal) {
      this.$emit('update-value', val)
    },

    value(val) {
      if (val !== 'Популярные товары') {
        this.searchString = val
      }
    },
  },

  mounted() {
    if (this.screenWidth <= 1025) {
      // @ts-ignore
      this.$refs.searchstring.$refs.search.focus()
      document.body.classList.add('overflow-hidden')
    }
    this.getSearchHistory()
  },

  beforeDestroy() {
    document.body.classList.remove('overflow-hidden')
  },

  methods: {
    clearSearch() {
      this.$emit('clear-search')
    },

    searchProducts() {
      if (!this.isSearchBased) {
        this.$emit('search-product')
      }
    },

    closeSearch() {
      this.clearSearch()
      this.$emit('close-mobile-search')
    },

    removeSearchHistory(query: string) {
      this.$services.search.removeSearchHistory(query)
      this.getSearchHistory()
    },

    getSearchHistory() {
      this.searchHistory = this.$services.search.showSearchHistory() ?? []
    },

    selectListQuery(query) {
      this.$emit('update-value', query)
    },
  },
})
