
// @ts-nocheck
import Vue from 'vue'
import RocketPharmLogo from 'icons/rph-logo.svg?inline'
import RPHHeaderBurger from 'rph/atoms/RPHHeaderBurger/index.vue'
import RPHHeaderWhatsApp from 'rph/molecules/RPHHeaderWhatsApp/index.vue'
import RPHHeaderLocation from 'rph/organisms/RPHHeaderLocation/index.vue'
import RPHBurgerMenu from 'rph/organisms/RPHBurgerMenu/index.vue'
import RPHSearchBar from 'rph/organisms/RPHSearchBar/index.vue'
import RPHTextBadge from 'rph/atoms/RPHTextBadge/index.vue'
import RPHHeaderBonus from 'rph/organisms/RPHHeaderBonus/index.vue'

export default Vue.extend({
  name: 'RPHHeaderMobile',
  components: {
    RocketPharmLogo,
    RPHHeaderBurger,
    RPHHeaderWhatsApp,
    RPHHeaderLocation,
    RPHBurgerMenu,
    RPHSearchBar,
    RPHTextBadge,
    RPHHeaderBonus,
  },
  props: {
    headerHideOffset: {
      type: Number,
      default: 120,
    },
    isMain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      burgerIsOpen: false,
      lastScrollPosition: 0,
    }
  },
  computed: {
    isHeaderShown(): boolean {
      return this.$store.getters['rph-menu/isHeaderShown']
    },
    isProductPage() {
      return this.$route.name === 'product-slug'
    },
    scrollOffset(): number {
      return this.isMain ? 550 : 40
    },
  },
  mounted() {
    this.lastScrollPosition = window.scrollY
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      if (window.scrollY < 0) {
        return
      }
      if (this.isMain && Math.abs(window.scrollY - this.lastScrollPosition) < this.scrollOffset) {
        this.$store.commit('rph-menu/setIsHeaderShown', window.scrollY < this.scrollOffset)
        this.lastScrollPosition = window.scrollY
      }
      // @ts-ignore
      if (Math.abs(window.scrollY - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      this.$store.commit('rph-menu/setIsHeaderShown', window.scrollY < this.lastScrollPosition)
      this.lastScrollPosition = window.scrollY
    },
  },
})
