
import BackIcon from '~/assets/icons/back-arrow.svg?inline'
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'cart-layout',
  components: {
    LazyHydrate,
    BackIcon,
    RPHToastContainer: () => import('rph/organisms/RPHToastContainer/index.vue'),
  },
  methods: {
    goBack() {
      this.$router.push('/')
    },
  },
}
