
// @ts-nocheck
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loupe from 'icons/loupe.svg?inline'
import BulletList from 'icons/bullet-list.svg?inline'
import Close from 'icons/close-common.svg?inline'
import breakpoints from '@/mixins/breakpoints'

export default Vue.extend({
  name: 'RPHSearchBar',
  components: {
    Loupe,
    BulletList,
    Close,
    RPHSearchResult: () => import('rph/molecules/RPHSearchResult/index.vue'),
    RPHCatalogList: () => import('rph/molecules/RPHCatalogList/index.vue'),
    RPHInputSearch: () => import('rph/atoms/RPHInputSearch/index.vue'),
  },

  mixins: [breakpoints],
  props: {
    /**
     * Type of the search bar
     * @values small, large
     */
    isPharmacy: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'small',
    },
    /**
     * show enter button
     */
    button: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      catalogIsOpen: false,
      value: '',
      loading: false,
      categoryId: '',
      isResultsOpen: false,
      topPositionOfSearch: 0 as any,
      debouncedSearch: null as any,
    }
  },

  async asyncData({ store }) {
    !store.getters['rph-products/popularProducts'].length
      ? store.dispatch('rph-products/getPopularProducts', {
          params: {
            initial: 'popular products',
            expand:
              'annotation,specification,images,prices,seo,category,extra,producers,specification',
            'per-page': 20,
            'filter[is_season]': 1,
            'filter[sort_stock]': 1,
          },
        })
      : ''
  },

  computed: {
    ...mapGetters({
      foundProducts: 'rph-products/foundProducts',
      searchPopularProducts: 'rph-products/searchPopularProducts',
      popularProducts: 'rph-products/popularProducts',
    }),

    searchProducts() {
      if (this.foundProducts.length) {
        return this.foundProducts
      } else if (this.searchPopularProducts.length) {
        return this.searchPopularProducts
      } else {
        return this.popularProducts
      }
    },

    searchProductsIds() {
      return this.searchProducts.map((product) => product.id)
    },

    isWideTabled() {
      return this.screenWidth < 1025
    },
  },
  watch: {
    value: {
      handler(data) {
        if (data.length >= 2) {
          this.loading = true
          this.debouncedSearch()
        } else {
          this.clearFoundProducts()
        }
      },
    },

    // collapse() {
    //   this.clearSearch()
    // },
    $route() {
      this.clearSearch()
    },
    async filterByCategoryId(val) {
      await this.$router.push({
        query: { ...this.$route.query, category: val },
      })
    },
  },
  beforeMount() {
    this.debouncedSearch = this.$debounce(this.findProductHandler, 500)
  },
  mounted() {
    if (!this.collapse) {
      window.addEventListener('scroll', this.onScrollHandler.bind(this))
    }
    const searchBar = this.$refs.searchBar as HTMLElement
    if (searchBar) {
      this.topPositionOfSearch = searchBar.getBoundingClientRect()
    }
    if (this.foundProducts.length === 0) {
      this.findProductHandler()
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollHandler.bind(this))
  },
  methods: {
    ...mapActions({
      findProduct: 'rph-products/getSearchBlockProducts',
    }),
    ...mapMutations({
      clearFoundProducts: 'rph-products/clearFoundProducts',
    }),
    catalogHandler() {
      this.catalogIsOpen = !this.catalogIsOpen
      this.isResultsOpen = false
    },
    clickOutside() {
      if (!this.loading && !this.isWideTabled) {
        this.catalogIsOpen = false
        this.isResultsOpen = false
        this.value = ''
      }
    },
    clearSearch() {
      this.catalogIsOpen = false
      this.isResultsOpen = false
      this.value = ''
      this.clearFoundProducts()
    },
    closeMobileSearch() {
      this.isResultsOpen = false
    },
    searchProduct() {
      if (this.$route.path !== '/search') {
        this.$router.push({
          path: '/search',
          query: { search: this.value, page: '1' },
        })
      } else {
        this.$router.push({ query: { search: this.value, page: '1' } })
      }
      this.$services.search.addSearchQuery(this.value)
      this.sendAnyQuerySearchEvent()
      this.clearSearch()
    },
    findProductHandler: async function (this: any) {
      this.clearFoundProducts()
      try {
        await this.findProduct({
          searchString: this.value.length >= 2 ? this.value : '',
          params: { productsSize: 5, showUnavailable: true },
        })
      } finally {
        if (this.value) {
          this.sendAnyQuerySearchEvent()
        }
        this.loading = false
      }
    },

    sendAnyQuerySearchEvent() {
      this.$services.anyQuery.anyQuerySearchEvent({
        originalSearchTerm: this.value,
        searchTerm: this.value,
        pageNumber: 1,
        pageProducts: this.searchProductsIds,
        isFromRedirect: false,
        isZeroQuery: false,
      })
    },
    filterByCategoryId(val: any) {
      this.$router.push({
        query: { ...this.$route.query, categories: val },
      })
    },
    closeCatalog() {
      this.catalogIsOpen = false
      this.isResultsOpen = false
    },
    onScrollHandler() {
      const scrollThreshold = 400
      const elementTop = this.topPositionOfSearch.top

      if (window.scrollY > elementTop + scrollThreshold) {
        if (!this.loading) {
          this.isResultsOpen = false
        }
      }
    },
    openResultModal() {
      if (!this.isResultsOpen) {
        this.isResultsOpen = true
        this.catalogIsOpen = false
      }

      if (!this.isWideTabled) {
        setTimeout(() => {
          if (this.$refs.searchWindow && !this.collapse) {
            const searchWindow = this.$refs.searchWindow.$el
            const windowHeight = window.innerHeight
            const searchWindowPosition =
              searchWindow.getBoundingClientRect().top + window.pageYOffset
            const scrollPosition =
              searchWindowPosition - windowHeight / 1.2 + searchWindow.offsetHeight / 1.2

            window.scrollTo({
              top: scrollPosition,
              behavior: 'smooth',
            })
          }
        }, 0)
      }
    },

    updateValue(newVal: string) {
      this.value = newVal
    },
  },
})
