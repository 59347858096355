var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],ref:"searchBar",class:[
    'rph-search',
    `rph-search--${_vm.type}`,
    (_vm.catalogIsOpen || _vm.isResultsOpen) && 'rph-search--open',
  ]},[_c('button',{class:[
      'rph-search__button',
      (_vm.catalogIsOpen || _vm.isResultsOpen) && 'rph-search__button--open',
    ],attrs:{"title":"Открыть каталог"},on:{"click":_vm.catalogHandler}},[(_vm.catalogIsOpen)?_c('Close'):_c('BulletList'),_vm._v(" "),_c('span',[_vm._v("Каталог")])],1),_vm._v(" "),_c('RPHInputSearch',{attrs:{"type":_vm.type,"loading":_vm.loading,"is-pharmacy":_vm.isPharmacy},on:{"clear-search":_vm.clearSearch,"find-product":_vm.searchProduct,"open-results":_vm.openResultModal},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('RPHCatalogList',{directives:[{name:"show",rawName:"v-show",value:(_vm.catalogIsOpen),expression:"catalogIsOpen"}],attrs:{"catalog-is-open":_vm.catalogIsOpen},on:{"on-change-category":_vm.filterByCategoryId,"close-catalog":_vm.closeCatalog}})],1),_vm._v(" "),(_vm.isResultsOpen)?[_c('RPHSearchResult',{ref:"searchWindow",attrs:{"type":_vm.type,"value":_vm.value.length >= 2 ? _vm.value : 'Популярные товары',"products-arr":_vm.searchProducts,"loading":_vm.loading,"show-results":_vm.isResultsOpen},on:{"clear-search":_vm.clearSearch,"search-product":_vm.searchProduct,"update-value":_vm.updateValue,"close-mobile-search":_vm.closeMobileSearch}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }