
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHHeaderBonus',
  computed: {
    bonuses(): number {
      return this.$store.getters['rph-users/mindboxUserBonuses']
    },
    isAuth() {
      return this.$auth.loggedIn
    },
  }
})
