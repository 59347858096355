import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6b2d6d81&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=6b2d6d81&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2d6d81",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHInputSearch: require('/usr/src/app/src/components/v2/atoms/RPHInputSearch/index.vue').default,RPHProductCard: require('/usr/src/app/src/components/v2/molecules/RPHProductCard/index.vue').default,RPHButton: require('/usr/src/app/src/components/v2/atoms/RPHButton/index.vue').default,Loading: require('/usr/src/app/src/components/Loading.vue').default})
